* {
    box-sizing: border-box;
}

html {
    overflow: hidden;
}

:root {
    --amplify-primary-color: rgb(173, 8, 8);
    --amplify-primary-tint: #ff7701;
    --amplify-primary-shade: #ff7701;
}

@font-face {
    font-family: "Forte";
    src: local("Forte"),
        url("./fonts/Forte/FORTE.TTF") format("truetype");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "Papyrus";
    src: local("Papyrus"),
        url("./fonts//Papyrus/PAPYRUS.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Candara Light";
    src: local("Candara Light"),
        url("./fonts/CandaraLight/CandaraLight.TTF") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Candara";
    src: local("Candara"),
        url("./fonts/Candara/Candara.ttf") format("truetype");
}

@font-face {
    font-family: "Castellar";
    src: local("Castellar"),
        url("./fonts/Castellar/CASTELAR.ttf") format("truetype");
}


body {
    height: 100%;
    background-image: linear-gradient(to bottom, #080808, #222);
    min-height: 85vh;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

p {
    color: white !important;
}

h1 {
    color: white;
    display: inline-block;
    width: 100%;
    text-align: center;
    white-space: pre-line;
    font-family: "Castellar";
}

h3 {
    color: #eccc68;
    font-family: "Candara Light";
    font-weight: 600;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    color: #f5f5f5;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0 !important;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 85vh;
}

.ui.button {
    font-family: "Candara Light"
}

::-webkit-scrollbar {
    display: none;
}

.pushable {
    overflow-y: hidden;
}